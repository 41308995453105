import React from "react";

export class About extends React.Component {
    render() {
        return (
            <div className={"about"}>
                <div className={"content"}>
                    <h1>About</h1>
                    <p>
                        All the way back in 2005, a guy named <a href={"https://en.wikipedia.org/wiki/Alex_Tew"} target={"_new"}>Alex Tew</a> created <a href={"http://www.milliondollarhomepage.com/"} target={"_new"}>The Million Dollar Homepage</a>.
                    </p>
                    <p>
                        This was a novel thing back then, and it caught on. The concept is simple:
                    </p>
                    <p>
                        Take a website with a 1000 x 1000 pixel image, and sell these pixels for $1 each. And that's what happened!
                    </p>
                    <p>
                        In today's world, with <a href={"https://ethereum.org/"} target={"_new"}>blockchain</a> and <a href={"https://defiprime.com/"} target={"_new"}>DeFi</a> available,
                        we can take this old idea and make it new again. Using this technology we can dramatically reduce the cost for tile artists, as pixels can be sold back.
                    </p>
                    <p>
                        I hope this reincarnation can inspire people to create some fun and interesting tiles.
                        I consider this a little art experiment, mixing modern technology with human behavior.
                    </p>
                    <h1>How it works</h1>
                    <p>
                        On this website, there are 1 million pixels available, arranged as a 1000 x 1000 pixel image.
                    </p>
                    <p>
                        Using a Web3 blockchain enabled browser, you can own a tile on this image. A tile is a 10 x 10 pixel section.
                    </p>
                    <p>
                        As the owner of a tile you can do a couple of things:
                    </p>
                    <ul>
                        <li>You can color in the pixels in the tile, hence creating a small image</li>
                        <li>You can associate metadata with the tile, giving it a name, description and an external link</li>
                    </ul>
                    <p>
                        The tiles and pixels you create are saved on the Ethereum blockchain, forever becoming part of history!
                    </p>
                    <p>
                        So how do you become an owner? Through buying the tile for a minimum price of 100 DAI.
                    </p>
                    <p>
                        What's DAI? It's a decentralized stable coin, with 1 DAI ~= 1 USD. <a href={"https://community-development.makerdao.com/makerdao-mcd-faqs/faqs/dai"} target={"_new"}>Learn more about DAI</a>.
                    </p>
                    <p>
                        As the owner of a tile, you can always sell it back. This is a major change compared to Alex's million dollar homepage.
                    </p>
                    <p>
                        In short what happens is this:
                    </p>
                    <ol>
                        <li>You purchase a tile transferring 100 DAI into the MillionDAI smart contract</li>
                        <li>The contract keeps your DAI in return for giving you access as the owner of the tile</li>
                        <li>If at any point you'd like your DAI back, you simple sell the tile back to the smart contract</li>
                    </ol>
                    <p>
                        At that point the smart contract takes back the tile and will give you back the DAI used to initially purchase it.
                        When selling back the tile, some meta data is removed, like the name, description and tile link.
                        The pixels themselves remain however, until someone else buys the tile and changes them.
                    </p>
                    <p>
                        I mentioned earlier that there's a minimum price of 100 DAI. This is another difference compared to Alex's million dollar homepage,
                        with the price of a tile increasing or changing. As an owner of a tile you're free to set whatever price you want on your tiles,
                        as long as it's 100 DAI or more. So you could buy a tile at 250 DAI if you want to, and also change it back down later.
                        Whenever the price is changed, the difference in DAI is also transferred between you and the MillionDAI smart contract.
                    </p>
                    <p>
                        Now why would you set a higher price? Because, as a third difference to the original concept, others may outbid you. If someone wants
                        the tile you own, they can come in and pay 1 DAI more than the price you set on it. At that point you get your DAI back,
                        and the tile is now transferred to the new owner at the new price. Of course, if you want to discourage others from taking over your
                        tiles you would set a higher price on them, but that's up to you.
                    </p>
                    <h1>Voting on tiles</h1>
                    <p>
                        As tiles are purchased, the MillionDAI smart contract will hold DAI. This DAI is converted into <a href={"https://rdai.money/"} target={"_new"}>rDAI</a>,
                        thereby earning an interest over at <a href={"https://compound.finance/"} target={"_new"}>Compound</a>. This interest is used towards a price pool.
                    </p>
                    <p>
                        People can vote on tiles. After 45454 blocks, which is around 7 days on Mainnet, the votes are used to randomly pick a tile. The tile with the most votes
                        has a proportional higher likelihood of getting selected. Once a tile is selected, one of the tile owners, as a tile could have had many owners
                        throughout those 7 days, are again randomly selected. This selection process is weighted by the owner block time. So an owner having owned a tile for, say, 100 blocks,
                        has a higher likelihood than an owner only holding the tile for, say, 50 blocks.
                    </p>
                    <p>
                        Once selected the prize pool DAI is transferred to the lucky winner's address, and the process starts again.
                    </p>
                    <h1>Kovan and devs</h1>
                    <p>
                        Besides Mainnet, if you're a developer or a starving artist with insufficient DAI, you can also play around with this website using the <a href={"https://kovan.milliondai.website"}>Kovan test network</a>. <a href={"https://github.com/cfelde/milliondai"} target={"_new"}>Developer friendly information</a> also available.
                    </p>
                    <h1>Contact me?</h1>
                    <p>
                        If you'd like to reach out, you can find me on <a href={"https://twitter.com/cfelde"} target={"_new"}>Twitter</a>, <a href={"https://github.com/cfelde"} target={"_new"}>GitHub</a>, or <a href={"https://www.linkedin.com/in/cfelde"} target={"_new"}>LinkedIn</a>.
                    </p>
                </div>
            </div>
        );
    }
}