import React from "react";
import {withRouter} from "react-router";
import {getProvider} from "./Utils";
import Web3 from "web3";

export class TheMillion extends React.Component {
    constructor(props) {
        super(props);
        this.canvasRef = React.createRef();
        this.state = {
            selfAccount: false,
            noProvider: false,
            filterTiles: this.props.subset === "your-pixels",
            updatedTiles: new Map(),
            showVotes: false
        }
    }

    componentDidMount() {
        this.props.listener.setListener({
            tileUpdate: this.pixelListener.bind(this),
            showVotes: this.showVotes.bind(this)
        });
        this.canvasHandler();

        this.getChainData()
            .then(r => {
                this.setState(r);
            })
            .catch(e => {
                console.error(e);
            })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.canvasHandler();
    }

    pixelListener(tileId, pixels) {
        this.setState((prevState) => {
            const newUpdatedTiles = new Map(prevState.updatedTiles);
            newUpdatedTiles.set(tileId, pixels);

            return {updatedTiles: newUpdatedTiles}
        });
    }

    showVotes(show) {
        this.setState({
            showVotes: show
        })
    }

    async getChainData() {
        if (this.state.filterTiles === false) {
            return {
                selfAccount: true
            }
        }

        const provider = await getProvider();

        if (provider == null) {
            return {
                selfAccount: "No account",
                noProvider: true
            };
        }

        const web3 = new Web3(provider);
        const accounts = await web3.eth.getAccounts();

        return {
            selfAccount: accounts[0]
        }
    }

    canvasHandler() {
        const canvas = this.canvasRef.current;

        if (canvas == null || this.state.selfAccount === false) return;

        if (this.state.showVotes) {
            this.canvasHandlerVotes(canvas);
        } else {
            this.canvasHandlerNormal(canvas);
        }
    }

    canvasHandlerVotes(canvas) {
        const context = canvas.getContext("2d");
        const voteCounts = this.props.voting.votes;

        const min = Object.values(voteCounts).reduce((acc, v) => acc === undefined ? v["votes"] : Math.min(acc, v["votes"]), undefined);
        const max = Object.values(voteCounts).reduce((acc, v) => acc === undefined ? v["votes"] : Math.max(acc, v["votes"]), undefined);

        Object.entries(voteCounts).forEach((value) => {
            const [id, voteData] = value;
            const votes = voteData["votes"];

            const x1 = (parseInt(id) % 100) * 10;
            const y1 = Math.floor(parseInt(id) / 100) * 10;

            const weight = (votes - min) / (max - min)
            const h = (1.0 - weight) * 240
            const fillStyle = "hsl(" + h + ", 100%, 50%)";

            for (let index = 0; index < 100; index++) {
                const x2 = x1 + (index % 10);
                const y2 = y1 + Math.floor(index / 10);

                context.fillStyle = fillStyle;
                context.fillRect(x2, y2, 1, 1)
            }
        });

        for (let id = 0; id < 10000; id++) {
            if (voteCounts["" + id] !== undefined && voteCounts["" + id]["votes"] !== undefined) continue;

            const x1 = (id % 100) * 10;
            const y1 = Math.floor(id / 100) * 10;

            context.clearRect(x1, y1, 10, 10);
        }
    }

    canvasHandlerNormal(canvas) {
        const context = canvas.getContext("2d");
        const pixels = this.props.pixels;

        Object.entries(pixels).forEach((value) => {
            const [id, tile] = value;

            if (this.state.updatedTiles.get(id)) return;

            const {owner, pixels} = tile;
            const x1 = (parseInt(id) % 100) * 10;
            const y1 = Math.floor(parseInt(id) / 100) * 10;

            if (this.state.filterTiles && this.state.selfAccount !== false && this.state.selfAccount !== owner) {
                return;
            }

            if (pixels !== undefined) {
                pixels.forEach((fillStyle, index) => {
                    const x2 = x1 + (index % 10);
                    const y2 = y1 + Math.floor(index / 10);

                    context.fillStyle = fillStyle;
                    context.fillRect(x2, y2, 1, 1)
                });
            }
        });

        this.state.updatedTiles.forEach((pixels, id) => {
            const x1 = (parseInt(id) % 100) * 10;
            const y1 = Math.floor(parseInt(id) / 100) * 10;

            pixels.forEach((fillStyle, index) => {
                const x2 = x1 + (index % 10);
                const y2 = y1 + Math.floor(index / 10);

                context.fillStyle = fillStyle;
                context.fillRect(x2, y2, 1, 1)
            });

            for (let index = pixels.length; index < 100; index++) {
                const x2 = x1 + (index % 10);
                const y2 = y1 + Math.floor(index / 10);

                context.clearRect(x2, y2, 1, 1)
            }
        });

        for (let id = 0; id < 10000; id++) {
            if (pixels["" + id] !== undefined
                && pixels["" + id].pixels !== undefined
                && pixels["" + id].pixels.length > 0) continue;

            if (this.state.updatedTiles.get("" + id) !== undefined
                && this.state.updatedTiles.get("" + id) !== null) continue;

            const x1 = (id % 100) * 10;
            const y1 = Math.floor(id / 100) * 10;

            context.clearRect(x1, y1, 10, 10);
        }
    }

    busyOrError() {
        if (this.state.filterTiles && this.state.noProvider) {
            return (
                <div className={"no-provider"}>
                    Sorry, but it doesn't look like your browser supports Web3.
                    <br/>
                    <br/>
                    Have a look at <a href={"https://www.meetdapper.com/"} target={"_new"}>Dapper</a> or <a
                    href={"https://metamask.io/"} target={"_new"}>MetaMask</a> to get started.
                </div>
            )
        } else {
            return (<></>)
        }
    }

    render() {
        const { history } = this.props;
        const subset = this.props.subset || "tile";

        return (
            <>
                <canvas
                    className={"the-canvas"}
                    height={"1000px"}
                    width={"1000px"}
                    ref={this.canvasRef}
                />
                <img
                    className={"the-img"}
                    src={"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="}
                    height={"1000px"}
                    width={"1000px"}
                    useMap={"#tile-map"}
                    alt={""}
                />
                <map name={"tile-map"}>
                    {
                        Object.entries(this.props.pixels).map((value) => {
                            const [id] = value;

                            const x = (parseInt(id) % 100) * 10;
                            const y = Math.floor(parseInt(id) / 100) * 10;

                            if (this.state.showVotes) {
                                const voteCounts = this.props.voting.votes;
                                let voteText

                                if (voteCounts[id] === undefined || voteCounts[id]["votes"] === undefined) {
                                    voteText = "No votes";
                                } else if (voteCounts[id]["votes"] === 1) {
                                    voteText = "1 vote on tile";
                                } else {
                                    voteText = voteCounts[id]["votes"] + " votes on tile";
                                }

                                return <area
                                    key={id}
                                    shape={"rect"}
                                    coords={x + "," + y + "," + (x + 10) + "," + (y + 10)}
                                    alt={voteText}
                                    title={voteText}
                                    href={"/" + subset + "/" + (parseInt(id) + 1)}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        history.push("/" + subset + "/" + (parseInt(id) + 1))
                                    }}
                                />
                            } else {
                                return <area
                                    key={id}
                                    shape={"rect"}
                                    coords={x + "," + y + "," + (x + 10) + "," + (y + 10)}
                                    alt={"Tile " + (parseInt(id) + 1)}
                                    title={"Tile " + (parseInt(id) + 1)}
                                    href={"/" + subset + "/" + (parseInt(id) + 1)}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        history.push("/" + subset + "/" + (parseInt(id) + 1))
                                    }}
                                />
                            }
                        })
                    }
                </map>

                {this.busyOrError()}
            </>
        );
    }
}

export const TheMillionWithRouter = withRouter(TheMillion);
