import React from "react";
import Web3 from "web3";
import {getProvider, getTileOffset, getUrlNetwork} from "./Utils";
import TruffleContract from "@truffle/contract";
import BN from "bn.js";

export class ShowTile extends React.Component {
    constructor(props) {
        super(props);
        this.canvasRef = React.createRef();
        this.state = {
            selfAccount: false,
            metadata: {},
            chainDataOverride: {},
            vote: {},
            urlNetwork: getUrlNetwork(),
            pixels: props.tile.pixels,

            // For edit
            newTilePrice: props.tile.minNewPrice,
            newTilePriceRaw: (props.tile.minNewPrice && Web3.utils.fromWei(props.tile.minNewPrice)) || "0",
            newTilePriceTooLow: false,

            // For edit
            newTileMetaUri: props.tile.uri,

            // For edit
            newTilePixelColor: "#000",
            newTilePixels: props.tile.pixels,

            editPriceActive: false,
            editURIActive: false,
            editSellActive: false,
            editPixelsActive: false,
            voteMode: props.voteMode || false,
            showVotes: false,

            txActive: false,
            noProvider: false,
            haveError: false,
            wrongNetwork: false
        }
    }

    componentDidMount() {
        this.canvasHandler();

        this.getURIData(this.props.tile.uri)
            .then(data => {
                this.setState({metadata: {...this.state.metadata, ...data}})
            })
            .catch(e => {
                console.error(e);
            });

        this.getChainData(this.props.tile.id)
            .then(data => {
                this.setState(data);
            })
            .catch(e => {
                this.setState({selfAccount: "No account"});
            });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.canvasHandler();
    }

    canvasHandler() {
        const canvas = this.canvasRef.current;
        if (canvas == null) return;

        const context = canvas.getContext("2d");

        let pixels = this.state.chainDataOverride.pixels || this.state.pixels;

        if (this.state.editPixelsActive) {
            pixels = this.state.newTilePixels;

            if (pixels.length < 100) {
                while (pixels.length < 100) {
                    pixels.push(this.state.newTilePixelColor);
                }
                this.setState({newTilePixels: pixels})
            }
        }

        pixels.forEach((fillStyle, index) => {
            const x = index % 10;
            const y = Math.floor(index / 10);

            context.fillStyle = fillStyle;
            context.fillRect(x * 20, y * 20, 20, 20)
        });
    }

    listenerHandler(pixels) {
        const listener = this.props.listener.getListener();
        listener.tileUpdate(this.props.tileId, pixels);
    }

    tileOwner() {
        if (this.state.chainDataOverride.owner)
            return this.state.chainDataOverride.owner;
        else
            return this.props.tile.owner;
    }

    tileMinNewPrice() {
        if (this.state.chainDataOverride.minNewPrice)
            return this.state.chainDataOverride.minNewPrice;
        else
            return this.props.tile.minNewPrice;
    }

    inputPriceHandler(e) {
        this.setState({newTilePriceRaw: e.target.value})

        try {
            let newTilePrice = Web3.utils.toWei(e.target.value);

            if (this.state.selfAccount === this.tileOwner()) {
                this.setState({newTilePriceTooLow: Web3.utils.toBN(newTilePrice).lt(Web3.utils.toBN("100000000000000000000"))})
            } else if (Web3.utils.toBN(newTilePrice).lt(Web3.utils.toBN(this.tileMinNewPrice()))) {
                this.setState({newTilePriceTooLow: true})
            } else {
                this.setState({newTilePriceTooLow: false})
            }

            this.setState({newTilePrice: newTilePrice})
        } catch (e) {
            this.setState({newTilePriceTooLow: true})
        }
    }

    inputTileMetaUriHandler(e) {
        this.setState({newTileMetaUri: e.target.value})
    }

    confirmPriceHandler(e) {
        e.preventDefault();
        if (this.state.newTilePriceTooLow) return;

        const newTilePrice = this.state.newTilePrice;
        const tileId = this.props.tile.id;

        this.setState({txActive: true});

        this.executeTileEnter(tileId, newTilePrice)
            .then(tx => {
                console.log(tx);
                this.setState({txActive: false, editPriceActive: false});
                return this.getChainData(tileId);
            })
            .then(chainData => {
                this.setState(chainData);
            })
            .catch(e => {
                this.setState({txActive: false, editPriceActive: false, haveError: true});
                console.error(e);
            });
    }

    saveTileMetaUri(e) {
        e.preventDefault();
        if (!this.isValidURL(this.state.newTileMetaUri)) return;

        const newTileMetaUri = this.state.newTileMetaUri;
        const tileId = this.props.tile.id;

        this.setState({txActive: true});

        this.executeSaveTileMetaURI(tileId, newTileMetaUri)
            .then(tx => {
                console.log(tx);
                this.setState({txActive: false, editURIActive: false});
                return this.getChainData(tileId);
            })
            .then(chainData => {
                this.setState(chainData);
            })
            .catch(e => {
                this.setState({txActive: false, editURIActive: false, haveError: true});
                console.error(e);
            });
    }

    sellTile(e) {
        e.preventDefault();

        const tileId = this.props.tile.id;

        this.setState({txActive: true});

        this.executeSellTile(tileId)
            .then(tx => {
                console.log(tx);
                this.setState({txActive: false, editSellActive: false});
                return this.getChainData(tileId);
            })
            .then(chainData => {
                this.setState(chainData);
            })
            .catch(e => {
                this.setState({txActive: false, editSellActive: false, haveError: true});
                console.error(e);
            });
    }

    savePixels(e) {
        e.preventDefault();

        const tileId = this.props.tile.id;
        const pixels = this.state.newTilePixels;
        const value = this.packagePixels(pixels);

        this.listenerHandler(pixels);

        this.setState({
            pixels: pixels,
            editPixelsActive: false,
            txActive: true
        });

        this.executeSavePixels(tileId, value)
            .then(tx => {
                console.log(tx);
                this.setState({txActive: false, editURIActive: false});
                return this.getChainData(tileId);
            })
            .then(chainData => {
                this.setState(chainData);
            })
            .catch(e => {
                this.setState({txActive: false, editURIActive: false, haveError: true});
                console.error(e);
            });
    }

    voteOnTile(e) {
        e.preventDefault();

        const pixels = this.state.chainDataOverride.pixels || this.state.pixels;
        if (pixels === undefined || pixels.length === 0) return;

        const tileId = this.props.tile.id;

        this.setState({txActive: true});

        this.executeVoteOnTile(tileId)
            .then(tx => {
                console.log(tx);
                this.setState({txActive: false});
                return this.getChainData(tileId);
            })
            .then(chainData => {
                this.setState(chainData);
            })
            .catch(e => {
                this.setState({txActive: false, haveError: true});
                console.error(e);
            });
    }

    showVotes(e) {
        e.preventDefault();

        const showVotes = this.state.showVotes;

        const listener = this.props.listener.getListener();
        listener.showVotes(!showVotes);

        this.setState({showVotes: !showVotes});
    }

    cancelPixels(e) {
        e.preventDefault();

        let pixels = this.state.pixels;
        this.listenerHandler(pixels);

        this.setState({editPixelsActive: false});
    }

    previewPixels(e) {
        e.preventDefault();

        let pixels = this.state.newTilePixels;
        this.listenerHandler(pixels);
    }

    parsePixelChainData(value) {
        if (value === null || value === undefined) {
            return [];
        }

        const bits = Web3.utils.toBN(value).toString(2).substring(2);

        let pixels = bits
            .match(/.{3}/g)
            .map(bitmap => bitmap
                .replace(/1/g, "f")
                .replace(/[^f]/g, "0"))
            .map(rgb => "#" + rgb)
            .slice(0, 100);

        while (pixels.length < 100) {
            pixels.push(this.state.newTilePixelColor);
        }

        return pixels;
    }

    packagePixels(pixels) {
        const bits = "11"
            + pixels
                .join("")
                .replace(/#/g, "")
                .replace(/f/g, "1")
                .replace(/[^1]/g, "0")
            + "11";

        const bn = new BN(bits, 2);

        return Web3.utils.toHex(bn);
    }

    async getURIData(uri) {
        if (!this.isValidURL(uri)) {
            return {
                name: "",
                description: "",
                url: ""
            };
        }

        const data = await fetch(uri).then(r => r.json()).catch(e => {
            console.error("Invalid meta on " + uri + ": " + e);
            return {}
        });

        const validKeys = {
            name: true,
            description: true,
            url: true
        };

        return Object
            .keys(data)
            .filter(k => validKeys[k])
            .reduce((acc, k) => {
                acc[k] = data[k];
                return acc;
            }, {
                name: "",
                description: "",
                url: ""
            });
    }

    async getChainData(tileId) {
        const provider = await getProvider();

        if (provider == null) {
            return {
                selfAccount: "No account"
            };
        }

        const web3 = new Web3(provider);

        let network = await web3.eth.net.getNetworkType();
        let urlNetwork = getUrlNetwork();

        if (urlNetwork === "kovan" && network !== "kovan") {
            console.error("Expected kovan net");
            return {
                wrongNetwork: true
            }
        } else if (urlNetwork === "main" && network !== "main") {
            console.error("Expected main net");
            return {
                wrongNetwork: true
            }
        }

        const accounts = await web3.eth.getAccounts();

        const millionDaiJson = await fetch("/_contract/MillionDai.json").then(r => r.json());
        const millionDai = TruffleContract(millionDaiJson);

        millionDai.setProvider(provider);
        const contract = await millionDai.deployed();

        const data = tileId !== undefined ? await contract.get(tileId) : {};
        const vote = this.state.voteMode ? await contract.getVote(accounts[0]) : {}
        const metadata = this.props.tile.uri === data["uri"] ? {} : await this.getURIData(data["uri"]);

        let output = {
            selfAccount: accounts[0],
            vote: vote
        }

        if (tileId !== undefined) {
            output = {
                ...output,
                pixels: this.parsePixelChainData(data["value"]),
                newTilePrice: data["minNewPrice"],
                newTilePriceRaw: Web3.utils.fromWei(data["minNewPrice"]),
                chainDataOverride: {
                    ...metadata,
                    ...{
                        uri: data["uri"],
                        price: data["price"],
                        minNewPrice: data["minNewPrice"],
                        owner: data["owner"]
                    }
                }
            }
        }

        if (accounts[0] === data["owner"]) {
            this.listenerHandler(output.pixels);

            return {
                ...output,
                ...{
                    newTilePrice: data["price"],
                    newTilePriceRaw: Web3.utils.fromWei(data["price"]),
                    newTileMetaUri: data["uri"],
                    newTilePixels: this.parsePixelChainData(data["value"])
                }
            }
        } else {
            return output;
        }
    }

    async ensureDaiAllowance(web3Provider, web3, millionDaiAddress, amount) {
        let accounts = await web3.eth.getAccounts();

        let daiJson = await fetch("/_contract/IERC20.json").then(r => r.json());
        let daiContract = TruffleContract(daiJson);

        daiContract.setProvider(web3Provider);
        daiContract.defaults({
            from: accounts[0]
        });

        let daiInstance = await daiContract.deployed();
        let allowance = await daiInstance.allowance(accounts[0], millionDaiAddress);

        if (allowance.gte(new BN(amount))) {
            return;
        }

        let newAllowance = web3.utils.toBN("0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff");
        await daiInstance.approve(millionDaiAddress, newAllowance.toString(10));
    }

    async executeTileEnter(tileId, tilePrice) {
        const provider = await getProvider();

        if (provider == null) {
            this.setState({noProvider: true});
            return;
        }

        const web3 = new Web3(provider);
        const accounts = await web3.eth.getAccounts();

        const millionDaiJson = await fetch("/_contract/MillionDai.json").then(r => r.json());
        const millionDai = TruffleContract(millionDaiJson);

        millionDai.setProvider(provider);
        const contract = await millionDai.deployed();

        if (typeof tilePrice === "object") {
            tilePrice = tilePrice.toString(10);
        }

        await this.ensureDaiAllowance(provider, web3, millionDai.address, tilePrice);

        console.debug("enter(" + tileId + ", " + tilePrice + ")");
        return contract.enter(tileId, tilePrice, {
            from: accounts[0],
            //gas: 1000000,
            gasPrice: Web3.utils.toWei("5", "gwei")
        });
    }

    async executeSaveTileMetaURI(tileId, tileMetaUri) {
        const provider = await getProvider();

        if (provider == null) {
            this.setState({noProvider: true});
            return;
        }

        const web3 = new Web3(provider);
        const accounts = await web3.eth.getAccounts();

        const millionDaiJson = await fetch("/_contract/MillionDai.json").then(r => r.json());
        const millionDai = TruffleContract(millionDaiJson);

        millionDai.setProvider(provider);
        const contract = await millionDai.deployed();

        console.debug("setTileURI(" + tileId + ", " + tileMetaUri + ")");
        return contract.setTileURI(tileId, tileMetaUri, {
            from: accounts[0],
            //gas: 1000000,
            gasPrice: Web3.utils.toWei("5", "gwei")
        });
    }

    async executeSellTile(tileId) {
        const provider = await getProvider();

        if (provider == null) {
            this.setState({noProvider: true});
            return;
        }

        const web3 = new Web3(provider);
        const accounts = await web3.eth.getAccounts();

        const millionDaiJson = await fetch("/_contract/MillionDai.json").then(r => r.json());
        const millionDai = TruffleContract(millionDaiJson);

        millionDai.setProvider(provider);
        const contract = await millionDai.deployed();

        console.debug("exit(" + tileId + ")");
        return contract.exit(tileId, {
            from: accounts[0],
            //gas: 1000000,
            gasPrice: Web3.utils.toWei("5", "gwei")
        });
    }

    async executeSavePixels(tileId, value) {
        const provider = await getProvider();

        if (provider == null) {
            this.setState({noProvider: true});
            return;
        }

        const web3 = new Web3(provider);
        const accounts = await web3.eth.getAccounts();

        const millionDaiJson = await fetch("/_contract/MillionDai.json").then(r => r.json());
        const millionDai = TruffleContract(millionDaiJson);

        millionDai.setProvider(provider);
        const contract = await millionDai.deployed();

        console.debug("setTileValue(" + tileId + ", " + value + ")");
        return contract.setTileValue(tileId, value, {
            from: accounts[0],
            //gas: 1000000,
            gasPrice: Web3.utils.toWei("5", "gwei")
        });
    }

    async executeVoteOnTile(tileId) {
        const provider = await getProvider();

        if (provider == null) {
            this.setState({noProvider: true});
            return;
        }

        const web3 = new Web3(provider);
        const accounts = await web3.eth.getAccounts();

        const millionDaiJson = await fetch("/_contract/MillionDai.json").then(r => r.json());
        const millionDai = TruffleContract(millionDaiJson);

        millionDai.setProvider(provider);
        const contract = await millionDai.deployed();

        console.debug("vote(" + tileId + ")");
        return contract.vote(tileId, {
            from: accounts[0],
            //gas: 1000000,
            gasPrice: Web3.utils.toWei("5", "gwei")
        });
    }

    bottomBuyButtons() {
        if (this.state.editPriceActive) {
            return (
                <div className={"tile-details-buttons"}>
                    <div className={"div-table"}>
                        <div className={"div-table-body"}>
                            <div className={"div-table-row"}>
                                <div className={"div-table-cell tile-price-container div-table-cell-left"}>
                                    <label htmlFor={"tile-price"}>DAI</label>
                                    <input
                                        id={"tile-price"}
                                        type={"text"}
                                        className={(() => {
                                            if (this.state.newTilePriceTooLow) {
                                                return "action-input action-input-invalid";
                                            } else {
                                                return "action-input";
                                            }
                                        })()}
                                        value={this.state.newTilePriceRaw}
                                        onChange={this.inputPriceHandler.bind(this)}
                                        autoFocus
                                    />
                                </div>
                                <div className={"div-table-cell div-table-cell-center"}>
                                    <a
                                        className={(() => {
                                            if (this.state.newTilePriceTooLow) {
                                                return "action-button action-button-disabled";
                                            } else {
                                                return "action-button";
                                            }
                                        })()}
                                        href={""}
                                        onClick={this.confirmPriceHandler.bind(this)}
                                    >
                                        Confirm price
                                    </a>
                                </div>
                                <div className={"div-table-cell div-table-cell-right"}>
                                    <a
                                        className={"action-button"}
                                        href={""}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            this.setState({editPriceActive: false});
                                        }}
                                    >
                                        Cancel
                                    </a>
                                </div>
                            </div>

                            {(() => {
                                if (this.state.newTilePriceTooLow) {
                                    return (
                                        <div className={"div-table-row"}>
                                            <div className={"div-table-cell text-center"}>
                                                Price too low or invalid
                                            </div>
                                        </div>
                                    );
                                }
                            })()}
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className={"tile-details-buttons"}>
                    <div className={"div-table"}>
                        <div className={"div-table-body"}>
                            <div className={"div-table-row"}>
                                <div className={"div-table-cell div-table-cell-left"}>
                                    <a
                                        className={"action-button"}
                                        href={""}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            this.setState({editPriceActive: true});
                                        }}
                                    >
                                        Buy this tile
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }

    bottomURIButtons() {
        return (
            <div className={"tile-details-buttons"}>
                <div className={"div-table"}>
                    <div className={"div-table-body"}>
                        <div className={"div-table-row"}>
                            <div className={"div-table-cell div-table-cell-left"}>
                                <a
                                    className={"action-button"}
                                    href={""}
                                    onClick={this.saveTileMetaUri.bind(this)}
                                >
                                    Save meta URI
                                </a>
                            </div>
                            <div className={"div-table-cell"}>
                                <div className={"action-spacer"}/>
                            </div>
                            <div className={"div-table-cell div-table-cell-right"}>
                                <a
                                    className={"action-button"}
                                    href={""}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        this.setState({editURIActive: false});
                                    }}
                                >
                                    Cancel
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    bottomSellButtons() {
        return (
            <div className={"tile-details-buttons"}>
                <div className={"div-table"}>
                    <div className={"div-table-body"}>
                        <div className={"div-table-row"}>
                            <div className={"div-table-cell div-table-cell-left"}>
                                <a
                                    className={"action-button"}
                                    href={""}
                                    onClick={this.sellTile.bind(this)}
                                >
                                    Confirm sell
                                </a>
                            </div>
                            <div className={"div-table-cell"}>
                                <div className={"action-spacer"}/>
                            </div>
                            <div className={"div-table-cell div-table-cell-right"}>
                                <a
                                    className={"action-button"}
                                    href={""}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        this.setState({editSellActive: false});
                                    }}
                                >
                                    Cancel
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    bottomPixelButtons() {
        return (
            <div className={"tile-details-buttons"}>
                <div className={"div-table"}>
                    <div className={"div-table-body"}>
                        <div className={"div-table-row"}>
                            <div className={"div-table-cell div-table-cell-left"}>
                                <a
                                    className={"action-button"}
                                    href={""}
                                    onClick={this.savePixels.bind(this)}
                                >
                                    Save pixels
                                </a>
                            </div>
                            <div className={"div-table-cell"}>
                                <div className={"action-spacer"}/>
                            </div>
                            <div className={"div-table-cell div-table-cell-right"}>
                                <a
                                    className={"action-button"}
                                    href={""}
                                    onClick={this.cancelPixels.bind(this)}
                                >
                                    Cancel
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    bottomOwnerButtons() {
        if (this.state.editPriceActive) {
            return this.bottomBuyButtons();
        } else if (this.state.editURIActive) {
            return this.bottomURIButtons();
        } else if (this.state.editSellActive) {
            return this.bottomSellButtons();
        } else if (this.state.editPixelsActive) {
            return this.bottomPixelButtons();
        } else {
            return (
                <div className={"tile-details-buttons"}>
                    <div className={"div-table"}>
                        <div className={"div-table-body"}>
                            <div className={"div-table-row"}>
                                <div className={"div-table-cell div-table-cell-left"}>
                                    <a
                                        className={"action-button"}
                                        href={""}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            this.setState({editPriceActive: true});
                                        }}
                                    >
                                        Edit tile price
                                    </a>
                                </div>
                                <div className={"div-table-cell div-table-cell-center"}>
                                    <a
                                        className={"action-button"}
                                        href={""}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            this.setState({editPixelsActive: true});
                                        }}
                                    >
                                        Edit tile pixels
                                    </a>
                                </div>
                                <div className={"div-table-cell div-table-cell-right"}>
                                    <a
                                        className={"action-button"}
                                        href={""}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            this.setState({editURIActive: true});
                                        }}
                                    >
                                        Edit tile meta
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }

    votingButtons() {
        const pixels = this.state.chainDataOverride.pixels || this.state.pixels;

        return (
            <div className={"tile-details-buttons"}>
                <div className={"div-table"}>
                    <div className={"div-table-body"}>
                        <div className={"div-table-row"}>
                            <div className={"div-table-cell div-table-cell-left"}>
                                <a
                                    className={(() => {
                                        if (pixels === undefined || pixels.length === 0) {
                                            return "action-button action-button-disabled";
                                        } else {
                                            return "action-button";
                                        }
                                    })()}
                                    href={""}
                                    onClick={this.voteOnTile.bind(this)}
                                >
                                    Vote on tile
                                </a>
                            </div>
                            <div className={"div-table-cell div-table-cell-center"}>
                                <a
                                    className={"action-button"}
                                    href={""}
                                    onClick={this.showVotes.bind(this)}
                                >
                                    {
                                        (() => {
                                            if (this.state.showVotes) {
                                                return "Hide votes";
                                            } else {
                                                return "Show votes";
                                            }
                                        })()
                                    }
                                </a>
                            </div>
                            <div className={"div-table-cell"}>
                                <div className={"action-spacer"}/>
                            </div>
                        </div>

                        {(() => {
                            if (pixels === undefined || pixels.length === 0) {
                                return (
                                    <div className={"div-table-row"}>
                                        <div className={"div-table-cell text-center"}>
                                            Select an active tile below before voting
                                        </div>
                                    </div>
                                );
                            }
                        })()}
                    </div>
                </div>
            </div>
        );
    }

    bottomButtons() {
        if (this.state.selfAccount === false) {
            return (
                <></>
            )
        }

        if (this.state.voteMode) {
            return this.votingButtons();
        } else if (this.state.selfAccount === this.tileOwner()) {
            return this.bottomOwnerButtons();
        } else {
            return this.bottomBuyButtons();
        }
    }

    busyOrError() {
        if (this.state.wrongNetwork) {
            return (
                <div className={"have-error"}>
                    Looks like we're expecting a different network.. make sure you're on the right network.
                    <br/>
                    <br/>
                    You can <a href={""} onClick={(e) => {
                    e.preventDefault();
                    this.setState({wrongNetwork: false, txActive: false});
                }}>close this</a>, check if you're on <a href={"https://kovan.milliondai.website"}>kovan</a> or <a href={"https://milliondai.website"}>mainnet</a>, and try again?
                </div>
            )
        } else if (this.state.noProvider) {
            return (
                <div className={"no-provider"}>
                    Sorry, but it doesn't look like your browser supports Web3.
                    <br/>
                    <br/>
                    Have a look at <a href={"https://www.meetdapper.com/"} target={"_new"}>Dapper</a> or <a
                    href={"https://metamask.io/"} target={"_new"}>MetaMask</a> to get started.
                </div>
            )
        } else if (this.state.txActive || this.state.selfAccount === false) {
            return (
                <div className={"loading"}>
                    <div className="loading-cube-grid">
                        <div className="loading-cube loading-cube-1"/>
                        <div className="loading-cube loading-cube-2"/>
                        <div className="loading-cube loading-cube-3"/>
                        <div className="loading-cube loading-cube-4"/>
                        <div className="loading-cube loading-cube-5"/>
                        <div className="loading-cube loading-cube-6"/>
                        <div className="loading-cube loading-cube-7"/>
                        <div className="loading-cube loading-cube-8"/>
                        <div className="loading-cube loading-cube-9"/>
                    </div>
                </div>
            )
        } else if (this.state.haveError) {
            return (
                <div className={"have-error"}>
                    Ops, seems we got an error on that transaction. Make sure you're on the right network?
                    <br/>
                    <br/>
                    Maybe <a href={""} onClick={(e) => {
                    e.preventDefault();
                    this.setState({haveError: false});
                }}>close this</a> or reload page, and try again?
                </div>
            )
        } else {
            return (<></>)
        }
    }

    isData(d) {
        return d !== null && d !== undefined
    }

    isValidURL(url) {
        const pattern = new RegExp("^https?:\\/\\/[^\\s$.?#].[^\\s]*$", "i");
        return this.isData(url) && !!pattern.test(url);
    }

    tableRow(label, value) {
        if (this.isData(value) && value !== "") {
            return (
                <div className={"div-table-row"}>
                    <div className={"div-table-cell cw-1"}>
                        {(() => {
                            if (label.length === 0 || !label.trim()) return label;
                            else return label + ":"
                        })()}
                    </div>
                    <div className={"div-table-cell"}>
                        {value}
                    </div>
                </div>
            );
        } else {
            return (<></>);
        }
    }

    tableRowOwner(label, value, isOwner) {
        if (!this.isData(value)) {
            return (<></>);
        }

        if (isOwner) {
            return (
                <div className={"div-table-row"}>
                    <div className={"div-table-cell cw-1"}>
                        {label}:
                    </div>
                    <div className={"div-table-cell"}>
                        {value}
                        <a
                            href={""}
                            className={"action-sell"}
                            onClick={(e) => {
                                e.preventDefault();
                                this.setState({editSellActive: true});
                            }}
                        >
                            [Sell this tile]
                        </a>
                    </div>
                </div>
            );
        } else {
            return (
                <div className={"div-table-row"}>
                    <div className={"div-table-cell cw-1"}>
                        {label}:
                    </div>
                    <div className={"div-table-cell"}>
                        {value}
                    </div>
                </div>
            );
        }
    }

    tableRowURL(label, value) {
        if (this.isData(value) && this.isValidURL(value)) {
            return (
                <div className={"div-table-row"}>
                    <div className={"div-table-cell cw-1"}>
                        {label}:
                    </div>
                    <div className={"div-table-cell"}>
                        <a href={value} target={"_new"}>{value}</a>
                    </div>
                </div>
            );
        } else {
            return (<></>);
        }
    }

    tableRowWinner(winnerAddress, winnerBlock, winnerTransaction) {
        if (this.isData(winnerAddress) && this.isData(winnerBlock) && this.isData(winnerTransaction)) {
            let txUrl;

            if (this.state.urlNetwork === "main") {
                txUrl = "https://etherscan.io/tx/" + winnerTransaction;
            } else if (this.state.urlNetwork === "kovan") {
                txUrl = "https://kovan.etherscan.io/tx/" + winnerTransaction;
            } else {
                txUrl = null;
            }

            if (winnerAddress !== "") {
                return (
                    <div className={"div-table-row"}>
                        <div className={"div-table-cell cw-1"}>
                            Last winner:
                        </div>
                        <div className={"div-table-cell"}>
                            {(() => {
                                if (txUrl) {
                                    return (
                                        <><a href={txUrl} target={"_new"}>{winnerAddress}</a> at block {winnerBlock}</>
                                    );
                                } else {
                                    return (
                                        <>{winnerAddress} at block {winnerBlock}</>
                                    );
                                }
                            })()}
                        </div>
                    </div>
                );
            } else {
                return (
                    <div className={"div-table-row"}>
                        <div className={"div-table-cell cw-1"}>
                            Last winner:
                        </div>
                        <div className={"div-table-cell"}>
                            No winner available on last round
                        </div>
                    </div>
                );
            }
        } else {
            return (<></>);
        }
    }

    tileDetailsURI() {
        const exampleTileJson = "{\n" +
            "\t\"name\": \"Tile name\",\n" +
            "\t\"description\": \"A short tile description\",\n" +
            "\t\"url\": \"http://example.com/external-link\"\n" +
            "}\n";

        return (
            <div className={"tile-details-uri"}>
                <div className={"div-table"}>
                    <div className={"div-table-body"}>
                        <div className={"div-table-cell"}>
                            Tile meta data is fetched from an external JSON file.
                            Simply provide a link below pointing to this file.
                            <br/><br/>
                            You can host it anywhere that's available online, as long as
                            it is structured as the example shown on the right.
                            You may omit fields of no interest.
                        </div>
                        <div className={"div-table-cell"}>
                            <pre>
                                <code>
                                    {exampleTileJson}
                                </code>
                            </pre>
                        </div>
                    </div>
                </div>
                <div className={"div-table"}>
                    <div className={"div-table-body"}>
                        <div className={"div-table-cell"}>
                            <input
                                id={"tile-meta-uri"}
                                type={"text"}
                                className={(() => {
                                    if (!this.isValidURL(this.state.newTileMetaUri)) {
                                        return "action-input action-input-full-width action-input-invalid";
                                    } else {
                                        return "action-input action-input-full-width";
                                    }
                                })()}
                                value={this.state.newTileMetaUri}
                                placeholder={"https://example.com/tile_meta.json"}
                                onChange={this.inputTileMetaUriHandler.bind(this)}
                                autoFocus
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    tileDetailsPixel(c, i) {
        let selected = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=";
        if (c === this.state.newTilePixelColor) {
            selected = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAUCAYAAAC07qxWAAAACXBIWXMAAAsSAAALEgHS3X78AAAAnUlEQVQoz63TXQrCMBAE4GUDJoGeRsHzVfR27W18EJqfdVaJYLHNPjiwEJqPNp1S8t5fQwgDGXJn5osFixWLFT8s2CFBRI5YH3SwPtVaGS8555yXD4wxTqUUVrCHXUppAZ572Km2YNfO0DA29MxnfVrDmMJfPYm8ZqPCd3C3QavRilZ1jbr3Z6TBhVsXbXzCn4isiKyIrIj0V+gi5AmiXJDnDPv15AAAAABJRU5ErkJggg==";
        }
        return (
            <div key={"palette-row-" + i} className={"palette-row"}>
                <div className={"palette-cell"}>
                    <img
                        src={selected}
                        height={"20px"}
                        width={"10px"}
                    />
                </div>
                <div
                    className={"palette-cell palette-cell-selectable"}
                    style={{backgroundColor: c}}
                    onClick={(e) => {
                        this.setState({newTilePixelColor: c})
                    }}
                />
            </div>
        );
    }

    tileDetailsPixels() {
        return (
            <div className={"tile-details-uri"}>
                <div className={"div-table"}>
                    <div className={"div-table-body"}>
                        <div className={"div-table-cell cw-3"}>
                            {(() => {
                                const colors = ["#000", "#00f", "#0f0", "#0ff"];
                                return colors.map(this.tileDetailsPixel.bind(this));
                            })()}
                        </div>
                        <div className={"div-table-cell cw-3"}>
                            {(() => {
                                const colors = ["#f00", "#f0f", "#ff0", "#fff"];
                                return colors.map(this.tileDetailsPixel.bind(this));
                            })()}
                        </div>
                        <div className={"div-table-cell"}>
                            Select a color from the palette,
                            and click on the tile canvas to change a pixel.
                            <br/>
                            <br/>
                            When you're happy save the pixels and the tile will be updated.
                            It will take a few minutes for the tile change to be visible to all.
                        </div>
                    </div>
                </div>
                <div className={"div-table"}>
                    <div className={"div-table-body"}>
                        <div className={"div-table-cell"}>
                            <div className={"pixel-edit-options"}>
                                <a
                                    href={""}
                                    onClick={(e) => {
                                        e.preventDefault();

                                        let pixels = [];

                                        while (pixels.length < 100) {
                                            pixels.push(this.state.newTilePixelColor);
                                        }

                                        this.setState({newTilePixels: pixels});
                                    }}
                                >
                                    [Clear]
                                </a>
                                <a
                                    href={""}
                                    onClick={this.previewPixels.bind(this)}
                                >
                                    [Preview]
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    tileDetailsText() {
        let {owner, price} = this.props.tile;
        let {name, description, url} = this.state.metadata;
        let chainDataOverride = this.state.chainDataOverride;

        if (this.isData(chainDataOverride)) {
            if (this.isData(chainDataOverride["name"])) {
                name = chainDataOverride["name"];
            }
            if (this.isData(chainDataOverride["description"])) {
                description = chainDataOverride["description"];
            }
            if (this.isData(chainDataOverride["url"])) {
                url = chainDataOverride["url"];
            }
            if (this.isData(chainDataOverride["owner"])) {
                owner = chainDataOverride["owner"];
            }
            if (this.isData(chainDataOverride["price"])) {
                price = chainDataOverride["price"];
            }
        }

        let exists = true;
        let isOwner = false;
        if (owner === undefined || owner === null || owner === "" || owner === "0x0000000000000000000000000000000000000000") {
            exists = false;
        } else if (owner === this.state.selfAccount) {
            isOwner = true;
            owner = "You";
        }

        return (
            <div className={"tile-details-text"}>
                <div className={"div-table"}>
                    <div className={"div-table-body"}>
                        {(() => {
                            if (exists) {
                                return (
                                    <>
                                        {(() => this.tableRow("Name", name))()}
                                        {(() => this.tableRow("Description", description))()}
                                        {(() => this.tableRowURL("Link", url))()}
                                        {(() => this.tableRow("Price", Web3.utils.fromWei(price)))()}
                                        {(() => this.tableRowOwner("Owner", owner, isOwner))()}
                                    </>
                                )
                            } else {
                                return (
                                    <div className={"div-table-cell"}>No current owner..</div>
                                );
                            }
                        })()}
                    </div>
                </div>
            </div>
        );
    }

    tileDetailsVote() {
        const exists = this.isData(this.state.vote.tile)
            && this.isData(this.state.vote.blockNumber)
            && this.state.vote.blockNumber > 0;

        const tileOffset = exists && getTileOffset(this.state.vote.tile).add(new BN(1));

        return (
            <div className={"tile-details-text"}>
                <div className={"div-table"}>
                    <div className={"div-table-body"}>
                        {(() => {
                            if (exists) {
                                const lastWinnerAddress = this.props.voting.lastWinnerAddress;
                                const lastWinnerBlock = this.props.voting.lastWinnerBlock;
                                const lastWinnerTransaction = this.props.voting.lastWinnerTransaction;

                                const activeVote = lastWinnerBlock === undefined || lastWinnerBlock === null || lastWinnerBlock < this.state.vote.blockNumber;
                                const voteStatus = activeVote ? "Active" : "Too old, please vote again";

                                return (
                                    <>
                                        {(() => this.tableRow("", "You voted for tile " + tileOffset))()}
                                        {(() => this.tableRow("", "Vote cast on block " + this.state.vote.blockNumber))()}
                                        <br/>
                                        {(() => this.tableRow("Vote status", voteStatus))()}
                                        <br/>
                                        {(() => this.tableRowWinner(lastWinnerAddress, lastWinnerBlock, lastWinnerTransaction))()}
                                    </>
                                )
                            } else {
                                return (
                                    <div className={"div-table-cell"}>No vote found..</div>
                                );
                            }
                        })()}
                    </div>
                </div>
            </div>
        );
    }

    tileDetails() {
        if (this.state.voteMode) {
            return this.tileDetailsVote();
        } else if (this.state.editURIActive) {
            return this.tileDetailsURI();
        } else if (this.state.editPixelsActive) {
            return this.tileDetailsPixels();
        } else {
            return this.tileDetailsText();
        }
    }

    editPixelCanvas() {
        return (
            <>
                <canvas
                    className={"the-edit-canvas"}
                    height={"200px"}
                    width={"200px"}
                    ref={this.canvasRef}
                />
                <img
                    src={"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="}
                    className={"the-edit-img"}
                    height={"200px"}
                    width={"200px"}
                    useMap={"#edit-map"}
                    alt={""}
                />
                <map name={"edit-map"}>
                    {
                        this.state.newTilePixels.map((value, index) => {
                            const x = (index % 10) * 20;
                            const y = Math.floor(index / 10) * 20;

                            return <area
                                key={"new-tile-pixel-" + index}
                                shape={"rect"}
                                coords={x + "," + y + "," + (x + 20) + "," + (y + 20)}
                                onClick={(e) => {
                                    e.preventDefault();
                                    let pixels = this.state.newTilePixels.map((c, i) => {
                                        if (i === index) return this.state.newTilePixelColor
                                        else return c;
                                    });
                                    this.setState({newTilePixels: pixels});
                                }}
                            />
                        })
                    }
                </map>
            </>
        );
    }

    render() {
        if (this.state.selfAccount === false) {
            return this.busyOrError();
        }

        let pixels = this.state.pixels;

        return (
            <>
                <div className={"tile-details-canvas"}>
                    {(() => {
                        if (this.state.editPixelsActive) {
                            return this.editPixelCanvas();
                        }
                        if (pixels !== undefined && pixels.length > 0) {
                            return (
                                <canvas
                                    height={"200px"}
                                    width={"200px"}
                                    ref={this.canvasRef}
                                />
                            );
                        } else {
                            return (
                                <div className={"no-tile"} />
                            );
                        }
                    })()}
                </div>
                <div className={"tile-details-right"}>
                    {this.tileDetails()}
                    {this.bottomButtons()}
                </div>

                {this.busyOrError()}
            </>
        );
    }
}