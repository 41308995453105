import BN from "bn.js";

export async function getProvider() {
    let web3Provider;

    // Modern dapp browsers...
    if (window.ethereum) {
        web3Provider = window.ethereum;

        try {
            // Request account access
            await window.ethereum.enable();
        } catch (error) {
            // User denied account access...
            console.error("User denied account access")
        }
    }
    // Legacy dapp browsers...
    else if (window.web3) {
        web3Provider = window.web3.currentProvider;
    }
    // If no injected web3 instance is detected, return null..
    else {
        web3Provider = null;
    }

    if (window.ethereum && window.ethereum.autoRefreshOnNetworkChange) {
        window.ethereum.autoRefreshOnNetworkChange = false;
    }

    return web3Provider
}

export function getUrlNetwork() {
    if (window.location.host.startsWith("kovan")) {
        return "kovan";
    } else if (window.location.host.startsWith("milliondai")) {
        return "main";
    }
}

export function getTileOffset(tileId) {
    return new BN(tileId.toString(10)).sub(new BN("57896044618658097711785492504343953926634992332820282019728792003956564819967"));
}
