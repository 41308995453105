import React from 'react';
import './css/App.css';
import {BrowserRouter, Link, Route, Switch} from "react-router-dom";
import {TheMillionWithRouter} from "./TheMillion";
import {ShowTile} from "./ShowTile";
import {About} from "./About";
import {getUrlNetwork} from "./Utils";

function Header() {
    return (
        <div className={"header"}>
            <h1 className={"title"}>
                The Million DAI website
            </h1>
            <span className={"banner"}>
              1,000,000 pixels - 1+ DAI per pixel - Own a piece of blockchain history!
          </span>
        </div>
    )
}

function Menu() {
    const urlNetwork = getUrlNetwork();

    return (
        <div className={"menu"}>
            <div>
                <Link to={"/"}>Website</Link>
            </div>
            <div>|</div>
            <div>
                <Link to={"/your-pixels"}>Your Pixels</Link>
            </div>
            <div>|</div>
            <div>
                <Link to={"/vote"}>Vote</Link>
            </div>
            <div>|</div>
            <div>
                <Link to={"/about"}>About</Link>
            </div>
            {(() => {
                if (urlNetwork === "kovan") {
                    return (
                        <>
                            <div>|</div>
                            <div>
                                <a href={"https://milliondai.website"}>Mainnet</a>
                            </div>
                        </>
                    );
                } else if (urlNetwork === "main") {
                    return (
                        <>
                            <div>|</div>
                            <div>
                                <a href={"https://kovan.milliondai.website"}>Kovan</a>
                            </div>
                        </>
                    );
                } else {
                    return (<></>);
                }
            })()}
        </div>
    )
}

function Loading() {
    return (
        <div className={"loading"}>
            <div className="loading-cube-grid">
                <div className="loading-cube loading-cube-1"></div>
                <div className="loading-cube loading-cube-2"></div>
                <div className="loading-cube loading-cube-3"></div>
                <div className="loading-cube loading-cube-4"></div>
                <div className="loading-cube loading-cube-5"></div>
                <div className="loading-cube loading-cube-6"></div>
                <div className="loading-cube loading-cube-7"></div>
                <div className="loading-cube loading-cube-8"></div>
                <div className="loading-cube loading-cube-9"></div>
            </div>
        </div>
    )
}

function Error() {
    return (
        <div className={"error"}>
            <div>
                Ops, something's wrong :(
                <br/>
                <br/>
                Please try again later!
            </div>
        </div>
    )
}

function Footer() {
    return (
        <div className={"footer"}>
            The Million DAI Website © 2020. All rights reserved. No responsible is taken for the content of external sites. Images featured on website are © of their respective owners.
        </div>
    )
}

class ListenerHandler {
    constructor() {
        this.listeners = []
    }

    setListener(listener) {
        this.listeners.length = 0;
        this.listeners.push(listener);
    }

    getListener() {
        if (this.listeners.length > 0)
            return this.listeners[0];
        else
            return {
                tileUpdate: function (tileId, pixels) {
                    // No-op
                },
                showVotes: function (show) {
                    // No-op
                }
            }
    }
}

class Website extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pixels: undefined,
            voting: undefined,
            haveError: false
        }
    }

    componentDidMount() {
        let pixelsJsonUrl = "/_data/pixels.json";
        let votingJsonUrl = "/_data/voting.json";

        if (window.location.host.startsWith("kovan")) {
            pixelsJsonUrl = "https://data.milliondai.website/kovan/pixels.json";
            votingJsonUrl = "https://data.milliondai.website/kovan/voting.json";
        } else if (window.location.host.startsWith("milliondai")) {
            pixelsJsonUrl = "https://data.milliondai.website/mainnet/pixels.json";
            votingJsonUrl = "https://data.milliondai.website/mainnet/voting.json";
        }

        fetch(pixelsJsonUrl).then(r => r.json()).then(r => {
            this.setState({
                pixels: r
            })
        }).catch(e => {
            console.error(e);
            this.setState({
                haveError: true
            });
        });

        fetch(votingJsonUrl).then(r => r.json()).then(r => {
            this.setState({
                voting: r
            })
        }).catch(e => {
            console.error(e);
            this.setState({
                haveError: true
            });
        });
    }

    render() {
        const pixels = this.state.pixels;
        const voting = this.state.voting;
        const haveError = this.state.haveError;

        if (pixels && voting) {
            return (
                <>
                    <Header/>
                    <Menu/>

                    <Switch>
                        <Route exact path={"/"}>
                            <div className={"desc"}>
                                <div key={"desc-fade-website"} className={"desc-fade"}>
                                    Click a tile below for details.
                                </div>
                            </div>
                            <div className={"the-million"}>
                                <TheMillionWithRouter
                                    key={"the-million-1"}
                                    pixels={pixels}
                                    voting={voting}
                                    listener={this.props.listener}
                                />
                            </div>
                        </Route>
                        <Route path={"/tile/:tileId"} render={(props) => {
                            const tileId = "" + (parseInt(props.match.params.tileId) - 1)
                            if (pixels[tileId]) {
                                return (
                                    <>
                                        <div className={"desc tile-details-desc"}>
                                            <ShowTile
                                                key={tileId}
                                                tileId={tileId}
                                                tile={pixels[tileId]}
                                                listener={this.props.listener}
                                            />
                                        </div>
                                        <div className={"the-million"}>
                                            <TheMillionWithRouter
                                                key={"the-million-1"}
                                                pixels={pixels}
                                                voting={voting}
                                                listener={this.props.listener}
                                            />
                                        </div>
                                    </>
                                );
                            }
                        }}
                        />
                        <Route path={"/your-pixels/:tileId"} render={(props) => {
                            const tileId = "" + (parseInt(props.match.params.tileId) - 1)
                            if (pixels[tileId]) {
                                return (
                                    <>
                                        <div className={"desc tile-details-desc"}>
                                            <ShowTile
                                                key={tileId}
                                                tileId={tileId}
                                                tile={pixels[tileId]}
                                                listener={this.props.listener}
                                            />
                                        </div>
                                        <div className={"the-million the-gray-million"}>
                                            <TheMillionWithRouter
                                                key={"the-million-2"}
                                                pixels={pixels}
                                                voting={voting}
                                                subset={"your-pixels"}
                                                listener={this.props.listener}
                                            />
                                        </div>
                                    </>
                                );
                            }
                        }}
                        />
                        <Route path={"/your-pixels"}>
                            <div className={"desc"}>
                                <div key={"desc-fade-your-pixels"} className={"desc-fade"}>
                                    Only your tiles are shown below. It will take a few minutes for updated tiles to show.
                                </div>
                            </div>
                            <div className={"the-million the-gray-million"}>
                                <TheMillionWithRouter
                                    key={"the-million-2"}
                                    pixels={pixels}
                                    voting={voting}
                                    subset={"your-pixels"}
                                    listener={this.props.listener}
                                />
                            </div>
                        </Route>
                        <Route path={"/vote/:tileId"} render={(props) => {
                            const tileId = "" + (parseInt(props.match.params.tileId) - 1)
                            if (pixels[tileId]) {
                                return (
                                    <>
                                        <div className={"desc tile-details-desc"}>
                                            <ShowTile
                                                key={tileId}
                                                tileId={tileId}
                                                tile={pixels[tileId]}
                                                voteMode={true}
                                                voting={voting}
                                                listener={this.props.listener}
                                            />
                                        </div>
                                        <div className={"the-million"}>
                                            <TheMillionWithRouter
                                                key={"the-million-1"}
                                                pixels={pixels}
                                                voting={voting}
                                                subset={"vote"}
                                                listener={this.props.listener}
                                            />
                                        </div>
                                    </>
                                );
                            }
                        }}
                        />
                        <Route path={"/vote"}>
                            <div className={"desc tile-details-desc"}>
                                <ShowTile
                                    //key={tileId}
                                    //tileId={tileId}
                                    //tile={pixels[tileId]}
                                    tile={{}}
                                    voteMode={true}
                                    voting={voting}
                                    listener={this.props.listener}
                                />
                            </div>
                            <div className={"the-million"}>
                                <TheMillionWithRouter
                                    key={"the-million-1"}
                                    pixels={pixels}
                                    voting={voting}
                                    subset={"vote"}
                                    listener={this.props.listener}
                                />
                            </div>
                        </Route>
                        <Route path={"/about"}>
                            <About />
                        </Route>
                    </Switch>

                    <div className={"desc"}></div>

                    <Footer/>
                </>
            );
        } else if (haveError) {
            return (
                <>
                    <Header/>
                    <Error/>
                </>
            )
        } else {
            return (
                <>
                    <Header/>
                    <Loading/>
                </>
            );
        }
    }
}

function App() {
    return (
        <BrowserRouter>
            <Website listener={new ListenerHandler()}/>
        </BrowserRouter>
    );
}

export default App;
